import { ReactNode } from 'react';

import { css } from '@emotion/react';
import { Apple, Android } from '@mui/icons-material';
import {
  Button,
  Rating,
  Stack,
  Box,
  Typography,
  Skeleton,
} from '@mui/material';
import { RouteLink } from '@scorenco/core';

import { Wrapper } from '../../primitives';

import { StoreGradeSliceFragment } from './StoreGradeSlice.fragment.generated';

type StoreCardProps = {
  grade: string;
  text?: string;
  href: string;
  ctaIcon?: ReactNode;
  ctaLabel: string;
};

const StoreCard = ({
  grade,
  text,
  href,
  ctaIcon,
  ctaLabel,
}: StoreCardProps) => {
  const gradeAsNumber = Number(grade?.replace(',', '.'));

  return (
    <Stack spacing={1} alignItems="center" justifyContent="center">
      <Typography
        color="primary"
        variant="h2"
        component="span"
        textAlign="center"
        css={(theme) => css`
          b {
            color: ${theme.vars.palette.secondary.main};
            font-weight: 900;
          }
        `}
      >
        {String(grade) || <Skeleton />}
      </Typography>

      <Rating value={gradeAsNumber} size="medium" readOnly precision={0.1} />

      <Typography textAlign="center" variant="body2" component="p">
        {text || <Skeleton />}
      </Typography>

      <RouteLink href={href}>
        <Button
          component="a"
          variant="contained"
          color="secondary"
          startIcon={ctaIcon}
        >
          {ctaLabel}
        </Button>
      </RouteLink>
    </Stack>
  );
};

type StoreGradeSliceProps = {
  slice: StoreGradeSliceFragment;
};

export const StoreGradeSlice = ({ slice }: StoreGradeSliceProps) => {
  return (
    <Wrapper marginY={5}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          flexWrap: 'wrap',
          maxWidth: 'md',
          margin: '0 auto',
          gap: 2,
        }}
      >
        <StoreCard
          grade={slice.androidNote as string}
          text={slice.androidDescription}
          href={slice.androidLink as string}
          ctaIcon={<Android />}
          ctaLabel={slice.ctaLabel as string}
        />

        <StoreCard
          grade={slice.iosNote as string}
          text={slice.iosDescription}
          href={slice.iosLink as string}
          ctaIcon={<Apple />}
          ctaLabel={slice.ctaLabel as string}
        />
      </Box>
    </Wrapper>
  );
};
